import React from 'react';
import { number, string } from 'prop-types';

const QuestionIcon = ({ width = 32, height = 32, color = 'black', className }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    aria-label="Close Icon"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={className}
  >
    <circle cx="16" cy="16" r="15.25" stroke={color} strokeWidth="1.5" />
    <path
      d="M15.007 18.9106H16.6768V18.8271C16.7046 17.1017 17.1499 16.3503 18.3744 15.585C19.5989 14.8406 20.3224 13.7691 20.3224 12.2316C20.3224 10.0609 18.7362 8.47461 16.2872 8.47461C14.033 8.47461 12.245 9.86608 12.1406 12.2316H13.8939C13.9982 10.5896 15.1462 9.92173 16.2872 9.92173C17.5952 9.92173 18.6527 10.7844 18.6527 12.1481C18.6527 13.2543 18.0196 14.0474 17.2056 14.5414C15.8419 15.3693 15.0279 16.1764 15.007 18.8271V18.9106ZM15.8976 23.0293C16.5864 23.0293 17.1499 22.4658 17.1499 21.777C17.1499 21.0882 16.5864 20.5247 15.8976 20.5247C15.2088 20.5247 14.6453 21.0882 14.6453 21.777C14.6453 22.4658 15.2088 23.0293 15.8976 23.0293Z"
      fill={color}
    />
  </svg>
);

QuestionIcon.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string,
};

export default QuestionIcon;

import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

import Button from '../common/Button/Button';
import phoneIllustration from '../../assets/contact-us.svg';
import useAnalytics from '../../hooks/useAnalytics';
import ContactUsForm from './ContactUsForm/ContactUsForm';

import { getMessages } from '../../locales';

const ContactUs = () => {
  const formspreeUrl = `https://formspree.io/f/${process.env.FORMSPREE_FORM_ID}`;

  const { trackEvent } = useAnalytics();
  const [status, setStatus] = useState('UNSET');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const submitForm = (data) => {
    setSubmitDisabled(true);

    axios({
      method: 'post',
      url: formspreeUrl,
      headers: {
        Accept: 'application/json',
      },
      data,
    })
      .then(() => {
        setStatus('SUCCESS');
        trackEvent('Submit Contact Us inquiry', { Location: 'Contact Us page' });
      })
      .catch(() => {
        setStatus('ERROR');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const messages = getMessages();

  return (
    <section className="container contact-us">
      <div className="row align-items-start">
        <div className="contact-us-content col-sm order-2 order-sm-1">
          {(status === 'UNSET' || status === 'ERROR') && (
            <div className="contact-form-wrapper">
              <h1>{messages['contactUs.title']}</h1>
              <ContactUsForm
                submitForm={submitForm}
                status={status}
                submitDisabled={submitDisabled}
              />
            </div>
          )}
          {status === 'SUCCESS' && (
            <div className="contact-form-success">
              <h1>{messages['contactUs.success.title']}</h1>
              <p>{messages['contactUs.success.desc']}</p>
              <Button
                title={messages['contactUs.success.button']}
                linkTo="/"
                buttonStyle="secondary"
                size="medium"
              />
            </div>
          )}
        </div>
        <div className="col-sm col-md-4 contact-us-image order-1 order-sm-2">
          <img
            width="420"
            height="398"
            src={phoneIllustration}
            alt={messages['contactUs.altimage']}
          />
        </div>
      </div>
      <div className="contact-us-disclaimer-block">
        <p className="contact-us-disclaimer-title">{messages['contactUs.disclaimer']}</p>
        <p>{ReactHtmlParser(messages['contactUs.disclaimerDetails'])}</p>
        <p>{ReactHtmlParser(messages['contactUs.disclaimerDetails2'])}</p>
      </div>
    </section>
  );
};

export default ContactUs;

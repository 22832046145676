import React from 'react';
import ReactTooltip from 'react-tooltip';
import { node, string, func } from 'prop-types';

import CloseIcon from '../../Icons/CloseIcon';
import Button from '../Button/Button';

const Tooltip = ({ children, id, afterShow = () => {} }) => (
  <ReactTooltip
    afterShow={afterShow}
    place="bottom"
    id={id}
    type="light"
    className="tooltip"
    globalEventOff="click"
  >
    <Button className="button--icon tooltip-close-icon" ariaLabel="Close Tooltip">
      <CloseIcon width={44} height={44} />
    </Button>
    {children}
  </ReactTooltip>
);

Tooltip.propTypes = {
  children: node,
  id: string.isRequired,
  afterShow: func,
};

export default Tooltip;

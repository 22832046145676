import React, { useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { string, func, bool } from 'prop-types';

import useAnalytics from '../../../hooks/useAnalytics';
import Button from '../../common/Button/Button';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import QuestionIcon from '../../Icons/QuestionIcon';
import Tooltip from '../../common/Tooltip/Tooltip';

import { contactUsValidations } from './validations';

import { linkColor, black, white } from '../../../styles/base/_variables.scss';

import { getMessages } from '../../../locales';

const ContactUsForm = ({ submitForm, status, submitDisabled }) => {
  const FormTextField = withStyles({
    root: {
      marginTop: '20px',
      marginBottom: '40px',
      '& input': {
        fontSize: 24,
      },
      '& textarea': {
        fontSize: 24,
        lineHeight: 1.25,
      },
      '& label': {
        fontSize: 24,
        color: black,
        backgroundColor: white,
      },
      '& label.Mui-focused': {
        color: linkColor,
      },
      'label + .MuiInput-formControl': {
        marginTop: '24px',
      },
    },
  })(TextField);

  const { handleSubmit, control, errors: fieldsErrors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { trackEvent } = useAnalytics();
  const tooltipRef = useRef(null);

  const trackEvents = () => {
    trackEvent('Click Reference number tooltip', { Location: 'Contact Us page' });
  };

  const messages = getMessages();

  return (
    <form noValidate onSubmit={handleSubmit(submitForm)} className="form">
      <div className="row">
        <div className="col-xs col-md-6">
          <Controller
            name="name"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['contactUs.name'] }}
                fullWidth
                name="name"
                id="name"
                autoComplete="family-name"
                label={messages['contactUs.name']}
              />
            }
            control={control}
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-xs col-md-6">
          <Controller
            name="email"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['contactUs.email'] }}
                fullWidth
                required
                name="email"
                id="email"
                autoComplete="email"
                label={messages['contactUs.email']}
                error={!!fieldsErrors.email}
                helperText={fieldsErrors?.email?.message}
              />
            }
            control={control}
            rules={contactUsValidations.email}
          />
        </div>
        <div className="col-xs col-md-6">
          <button
            data-tip
            data-for="referenceModal"
            data-event="click focus"
            ref={tooltipRef}
            type="button"
            className="button button--icon reference-number-question"
            aria-label="Information about the reference number"
          >
            <QuestionIcon color="#25466D" />
          </button>
          <Tooltip afterShow={trackEvents} id="referenceModal">
            <h2 className="tooltip-title">{messages['contactUs.tooltip.title']}</h2>
            <p className="tooltip-desc">{messages['contactUs.tooltip.desc']}</p>
          </Tooltip>
          <div>
            <Controller
              name="reference"
              as={
                <FormTextField
                  inputProps={{ 'aria-label': messages['contactUs.reference'] }}
                  fullWidth
                  name="reference"
                  id="reference"
                  label={messages['contactUs.reference']}
                />
              }
              control={control}
            />
          </div>
        </div>
        <div className="col-xs col-md-6">
          <Controller
            name="phone"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['contactUs.phone'] }}
                fullWidth
                name="phone"
                id="phone"
                autoComplete="tel"
                label={messages['contactUs.phone']}
              />
            }
            control={control}
          />
        </div>
        <div className="col-xs col-md-12">
          <Controller
            name="query"
            as={
              <FormTextField
                inputProps={{
                  'aria-label': messages['contactUs.query'],
                  'aria-labelledby': 'query-label',
                }}
                fullWidth
                name="query"
                required
                id="query"
                label={messages['contactUs.query']}
                multiline
                rowsMax={6}
                error={!!fieldsErrors.query}
                helperText={fieldsErrors?.query?.message}
              />
            }
            control={control}
            rules={contactUsValidations.query}
          />
        </div>
        {status === 'ERROR' && (
          <div className="col-xs col-md-12">
            <ErrorMessage message={messages['contactUs.errorMessage']} />
          </div>
        )}
        <div className="col-xs col-md-4">
          <Button
            type="submit"
            buttonStyle="primary"
            size="medium"
            wide
            isDisabled={submitDisabled}
            className="submitButton"
          >
            {messages['contactUs.submit']}
          </Button>
        </div>
      </div>
    </form>
  );
};

ContactUsForm.propTypes = {
  submitForm: func.isRequired,
  status: string,
  submitDisabled: bool,
};

export default ContactUsForm;
